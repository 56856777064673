import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import './i18n';
import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';
import { MixPanelToken } from 'Config/MixPanelKey';
import { DevelopmentMode, signalRUrls, apiUrls } from 'CommonApiController/AppConfig';
import "./CSS/style.css";

// Change variable to DevelopmentMode.PRODUCTION in production env
export const envModeVar = DevelopmentMode.STAGING;

export let signalRUrl = ""
export let BaseUrl = "";


if (envModeVar === DevelopmentMode.STAGING) {
    mixpanel.init(MixPanelToken.STAGING)
    signalRUrl = signalRUrls.stagingBaseURL
    BaseUrl = apiUrls.stagingBaseURL
}
else if (envModeVar === DevelopmentMode.DEVELOPMENT) {
    mixpanel.init(MixPanelToken.DEVELOPMENT)
    signalRUrl = signalRUrls.developmentBaseURL
    BaseUrl = apiUrls.developmentBaseURL
}
else if (envModeVar === DevelopmentMode.PRODUCTION) {
    mixpanel.init(MixPanelToken.PRODUCTION)
    signalRUrl = signalRUrls.productionBaseURL
    BaseUrl = apiUrls.productionBaseURL
} else if (envModeVar === DevelopmentMode.PREPRODUCTION) {
    mixpanel.init(MixPanelToken.DEVELOPMENT)
    signalRUrl = signalRUrls.preProductionBaseURL
    BaseUrl = apiUrls.preProductionBaseURL
}

ReactDOM.render(

    <MixpanelProvider mixpanel={mixpanel}>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </MixpanelProvider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
